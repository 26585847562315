/* party vars */
:root {
    --tnt-elections-democrat: #185cdb;
    --tnt-elections-republican: #db1818;
    --tnt-elections-independent: #d1940e;
    --tnt-elections-green: #108f13;
}

/* app styles */
.electionsstats .asset-header { margin-bottom: 20px; }
.electionsstats .elections-flags { margin-bottom: 10px; }
.electionsstats .political-party { font-weight: 500; font-size: 16px; margin-bottom: 15px; }
.electionsstats .political-party span { border-bottom: 2px solid #000; display: inline-block; padding: 2px 5px; } 
.electionsstats .asset-related { margin-top: 30px; }
.electionsstats .asset-description { font-size: 16px; margin-top: 20px; }
.electionsstats .election-date { font-size: 18px; margin-top: 5px; }
.electionsstats .election-date span { font-weight: 500; }
.electionsstats .election-info-list li { margin-bottom: 5px; }
.electionsstats .election-info-list li address { display: inline; }
.electionsstats .election-info-list li strong { margin-right: 5px; }
.electionsstats .asset-races { margin-top: 40px; }
.electionsstats .filter-race-year { display: inline-block; margin-left: 15px; }
.electionsstats .candidate-photo.party-border { border: 4px double #000; }

/* candidate */
.candidate-default .asset-content { margin-bottom: 40px; }

/* race */
.elections-race { margin-bottom: 30px; border: 1px solid #ddd; padding-top: 1px; }
.elections-race .table { margin-bottom: 0; border-top:0; }
.elections-race .table-responsive { border:0; margin-bottom: 0; }
.elections-race .table.table-bordered>thead>tr>th, 
.elections-race .table.table-bordered>tbody>tr>th, 
.elections-race .table.table-bordered>tfoot>tr>th, 
.elections-race .table.table-bordered>thead>tr>td, 
.elections-race .table.table-bordered>tbody>tr>td, 
.elections-race .table.table-bordered>tfoot>tr>td {
    border: 1px solid #fff;
}
.election-race-desc.table > tbody > tr > td { 
    border-top: 1px solid #fff;
    border-bottom: 1px solid #f7f7f7; 
}
.election-race-desc.table > tbody > tr > td > p {
    margin: 0;
}
.election-race-info { background-color: #f7f7f7; }
.election-race-info thead { background-color: #eee; }
.elections-race .table tfoot { font-size: 12px; }

.elections-race .party-label { margin-right: 4px; }
.elections-race .election-race-results .progress { 
    margin:0; 
    background-color: #ddd; 
    background-size: 5px 5px; 
    border-radius: 1px;
    box-shadow: none;
    height: 10px;
    margin-top: 6px;
    background-image: linear-gradient(45deg, rgb(255 255 255) 25%, transparent 25%, transparent 50%, rgb(255 255 255) 50%, rgb(255 255 255) 75%, transparent 75%, transparent); 
}
.elections-race .election-race-results .progress-bar { box-shadow: none; background-color: #333; }
.elections-race .election-race-results .winner {
    background-color: #038e06;
    border-radius: 20px;
    display: inline-block;
    height: 20px;
    width: 20px;
    text-align: center;
    margin-left: 10px;
    font-size: 13px;
    padding-top: 1px;
}
.elections-race .election-race-results .candidate-name.party-border { border-left: 10px solid #000; }
.elections-race .election-race-results .candidate-photo { margin-right: 8px; }

.elections-race-title h4 { margin-bottom:5px; }
.elections-race-title .precincts { display: block; margin-bottom: 10px; }

/* issue */
.electionsstats .issue-image { margin-bottom: 20px; }

/* inline assets */
.inline-electionsstats .card { border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; padding: 15px 0; }
.inline-electionsstats.tnt-inline-issue .card { padding: 20px 0; }
.inline-electionsstats .card .card-lead .tnt-summary { font-size: 14px; line-height: 16px; margin-bottom: 8px; }
.inline-electionsstats .card .election-website-links ul { margin-bottom:0; }
.inline-electionsstats .card .election-website-links ul a { color: #666; }
.inline-electionsstats .card .political-party { font-size: 13px; margin-bottom: 10px; }

/** party colors */
.democrat.party-color { color: var(--tnt-elections-democrat)!important; }
.republican.party-color { color: var(--tnt-elections-republican)!important; }
.independent.party-color { color: var(--tnt-elections-independent)!important; }
.green.party-color { color: var(--tnt-elections-green)!important; }

.democrat.party-background { background-color: var(--tnt-elections-democrat)!important; }
.republican.party-background { background-color: var(--tnt-elections-republican)!important; }
.independent.party-background { background-color: var(--tnt-elections-independent)!important; }
.green.party-background { background-color: var(--tnt-elections-green)!important; }

.democrat.party-border { border-color: var(--tnt-elections-democrat)!important; }
.republican.party-border { border-color: var(--tnt-elections-republican)!important; }
.independent.party-border { border-color: var(--tnt-elections-independent)!important; }
.green.party-border { border-color: var(--tnt-elections-green)!important; }

.elections-race .election-race-results .candidate-name { border-top: 1px solid #ddd!important; }